.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(3, 3, 3, 0.5);
  }
  
   
  .loader-container img {
   /* Set the size of the loader image */
   width: 40%; /* This will make the image fit the container width */
   height: 40%; /* This will make the image fit the container height */
   object-fit: contain; /* Adjusts the aspect ratio of the image */
   margin-right: -10%;
  }
  
  @media screen and (max-width: 600px) {
    .loader-container img {
      width: 80%; /* Adjust width for smaller screens */
      height: auto; /* Allow height to adjust proportionally */
      /* margin-top: -50%; */
    }
  }

  .rsw-editor{
    width: 100%;
    height: 250px !important;
    overflow-y: scroll !important;
  }
   

.rsw-editor .rsw-toolbar{
    position:absolute !important; 
    width:100% !important;
}

.rsw-editor .rsw-ce{
    margin-top: 4% !important;
}

@media (max-width: 768px) {

  .rsw-editor .rsw-toolbar {
      position: absolute; 
      width: 100%;
  }

  .rsw-editor .rsw-ce {
      margin-top: 15% !important;
  }
}
